<template>
  <div class="merchantConfiguration-page">
    <el-tabs v-model="active" type="card" @tab-click="handleClick">
      <el-tab-pane label="默认配置" name="1">
        <div class="add-btn">
          <el-button
            v-if="listFind('添加')"
            type="primary"
            size="small"
            @click="openDialogFun('新增')"
            >添加</el-button
          >
          <div class="table-box">
            <Table
              :table-data="tableData"
              :title-name="titleName"
              operation
              col-class-type
              operation-width="60"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="listFind('查看')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="openDialogFun('查看', scope.scopeRow)"
                  >查看</el-button
                >
              </template>
            </Table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="独立配置" name="2">
        <div class="add-btn">
          <el-button
            v-if="listFind('添加')"
            type="primary"
            size="small"
            @click="openDialogFun('新增')"
            >添加</el-button
          >
          <div class="table-box">
            <Table
              :table-data="tableData2"
              :title-name="titleName2"
              operation
              col-class-type
              operation-width="60"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="listFind('查看')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="openDialogFun('查看', scope.scopeRow)"
                  >查看</el-button
                >
              </template>
            </Table>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="dialog-box">
      <el-dialog
        v-if="dialogVisible"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="40%"
      >
        <el-form
          inline
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="渠道：" prop="channelNo">
            <el-select
              v-model="ruleForm.channelNo"
              :disabled="dialogTitle === '查看'"
              clearable
              placeholder="请选择渠道"
              @change="changeSelect"
            >
              <el-option
                v-for="(item, index) in list"
                :key="index"
                :label="item.channelName"
                :value="item.channelNo"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="active == '2'"
            label="独立收款公司："
            prop="companyId"
          >
            <companySelect
              ref="companySelect"
              :clearable="true"
              :company-tree="companyList"
              :valuename="valueName"
              :value="value"
              :disabled="dialogTitle === '查看'"
              @getValue="getAddGroupId"
              @getName="getName"
            ></companySelect>
          </el-form-item>
          <el-form-item label="商户类型：" prop="merchantType">
            <el-select
              v-model="ruleForm.merchantType"
              :disabled="dialogTitle === '查看'"
              clearable
              placeholder="请选择商户类型"
            >
              <el-option
                v-for="(item, index) in merchantTypeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商户名称：" prop="mchName">
            <el-input
              v-model.trim="ruleForm.mchName"
              :disabled="dialogTitle === '查看'"
              placeholder="请输入商户名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="商户简称：" prop="mchSimpleName">
            <el-input
              v-model.trim="ruleForm.mchSimpleName"
              :disabled="dialogTitle === '查看'"
              placeholder="请输入商户简称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="商户号：" prop="mchId">
            <el-input
              v-model.trim="ruleForm.mchId"
              :disabled="dialogTitle === '查看'"
              placeholder="请输入商户号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item v-if="active == '1'" label="APPID：" prop="appId">
            <el-input
              v-model.trim="ruleForm.appId"
              :disabled="dialogTitle === '查看'"
              placeholder="请输入APPID"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="KEY：" prop="key">
            <el-input
              v-model.trim="ruleForm.key"
              :disabled="dialogTitle === '查看'"
              placeholder="请输入key"
              clearable
            ></el-input>
          </el-form-item>
          <div>
            <el-form-item
              v-if="
                ruleForm.merchantType == 0 ||
                dialogTitle === '新增' ||
                ruleForm.certP12Path
              "
              label="商户证书(P12)："
              prop="certP12Path"
            >
              <uploadComponent
                v-if="dialogTitle === '新增'"
                :fileArr="certP12PathList"
                :acceptType="'.p12,.P12'"
                :disabled="dialogTitle === '查看'"
                @uploadSuccess="uploadSuccess($event, 'certP12Path')"
              ></uploadComponent>
              <span v-else>{{ ruleForm.certP12Path }}</span>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              v-if="
                ruleForm.merchantType == 0 ||
                dialogTitle === '新增' ||
                ruleForm.keyPemPath
              "
              label="商户私钥(PEM)："
              prop="keyPemPath"
            >
              <uploadComponent
                v-if="dialogTitle === '新增'"
                :fileArr="keyPemPathList"
                :acceptType="'.pem,.PEM'"
                :disabled="dialogTitle === '查看'"
                @uploadSuccess="uploadSuccess($event, 'keyPemPath')"
              ></uploadComponent>
              <span v-else>{{ ruleForm.keyPemPath }}</span>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              v-if="
                ruleForm.merchantType == 0 ||
                dialogTitle === '新增' ||
                ruleForm.certPemPath
              "
              label="商户证书(PEM)："
              prop="certPemPath"
            >
              <uploadComponent
                v-if="dialogTitle === '新增'"
                :fileArr="certPemPathList"
                :acceptType="'.pem,.PEM'"
                :disabled="dialogTitle === '查看'"
                @uploadSuccess="uploadSuccess($event, 'certPemPath')"
              ></uploadComponent>
              <span v-else>{{ ruleForm.certPemPath }}</span>
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            v-if="dialogTitle === '新增'"
            @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button :disabled="loading" type="primary" @click="confirmFun"
            >确 认</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import host from "@/api/static/host.js";
import uploadComponent from "./uploadComponent.vue";
import {
  queryChannelListAPI,
  queryWechatCertificatePageAPI,
  mchStatusEnableUpdateAPI,
  saveWechatCertificateAPI,
  getCompanyTree,
  independentWechatPage,
  mchStatusDisableUpdate,
  queryWechatMchLedgerPageAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {
    uploadComponent,
  },
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      ruleForm: {
        channelNo: "",
        channelName: "",
        mchName: "",
        mchId: "",
        merchantType: "",
        mchSimpleName: "",
        appId: "",
        key: "",
        certP12Path: "",
        keyPemPath: "",
        certPemPath: "",
        companyId: null,
        channelCompanyId: null,
        channelCompanyName: null,
      },
      rules: {
        channelNo: [
          { required: true, message: "请选择渠道", trigger: "change" },
        ],
        mchName: [
          { required: true, message: "请输入商户名称", trigger: "blur" },
        ],
        companyId: [
          { required: true, message: "请选择独立收款公司", trigger: "blur" },
        ],
        mchId: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              let list = [];
              if (this.active == "1") list = this.tableData;
              else list = this.tableData2;
              const arr = list.filter(
                (i) => i.channelNo == this.ruleForm.channelNo
              );
              if (arr.find((i) => i.mchId == value))
                callback("该渠道已存在相同的商户号");
              else callback();
            },
          },
        ],
        merchantType: [
          { required: true, message: "请选择商户类型", trigger: "change" },
        ],
        mchSimpleName: [
          { required: true, message: "请输入商户简称", trigger: "blur" },
        ],
        appId: [{ required: true, message: "请输入APPID", trigger: "blur" }],
        key: [{ required: true, message: "请输入key", trigger: "blur" }],
        certP12Path: [
          {
            required: false,
            message: "请上传商户证书(P12)",
            trigger: "change",
          },
        ],
        keyPemPath: [
          {
            required: false,
            message: "请上传商户私钥(PEM)",
            trigger: "change",
          },
        ],
        certPemPath: [
          {
            required: false,
            message: "请上传商户证书(PEM)",
            trigger: "change",
          },
        ],
      },
      channelList: [], //渠道数据
      list: [], //渠道数据
      merchantTypeList: [
        { label: "普通商户", value: 0 },
        { label: "特约商户", value: 1 },
      ], //商户类型
      certP12PathList: [], //商户证书(P12)
      keyPemPathList: [], //商户私钥(PEM)
      certPemPathList: [], //商户证书(PEM)
      tableData: [],
      titleName: [
        {
          title: "渠道",
          props: "channelName",
        },
        {
          title: "商户名称",
          props: "mchName",
        },
        {
          title: "商户简称",
          props: "mchSimpleName",
        },
        {
          title: "商户类型",
          props: "merchantType",
          SpecialJudgment: (res) => {
            return res === 1 ? "特约商户" : res === 0 ? "普通商户" : "";
          },
        },
        {
          title: "商户号",
          props: "mchId",
        },
        {
          title: "APPID",
          props: "appId",
        },
        {
          title: "商户KEY",
          props: "key",
        },
        {
          title: "商户证书(P12)",
          props: "certP12Path",
          SpecialJudgment: (res) => {
            return res ? res.slice(res.indexOf(".") + 1) : "";
          },
        },
        {
          title: "商户私钥(PEM)",
          props: "keyPemPath",
          SpecialJudgment: (res) => {
            return res ? res.slice(res.indexOf(".") + 1) : "";
          },
        },
        {
          title: "商户证书(PEM)",
          props: "certPemPath",
          SpecialJudgment: (res) => {
            return res ? res.slice(res.indexOf(".") + 1) : "";
          },
        },
        {
          title: "独立配置数",
          props: "independentConfigCount",
        },
        {
          title: "启用",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.mchStatus,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
                activeValue: 0,
                inactiveValue: 1,
              },
              on: {
                change: (val) => {
                  let text = "";
                  let flag = true;
                  if (val) {
                    let obj = this.platFormList.find(
                      (i) =>
                        i.mchId == params.mchId &&
                        i.merchantType &&
                        i.channelNo == params.channelNo
                    );
                    if (obj && obj.ledgerRatioReceiveCount > 0) {
                      text = "该渠道存在分账的商户，请先关闭渠道分账配置";
                      flag = false;
                    } else
                      text =
                        "禁用后，该渠道无法进行线上交易，独立配置的商户也会禁用。";
                  } else {
                    text =
                      "启用后该渠道的线上交易将收款至该商户号，并继承渠道独立配置的商户号。";
                  }
                  this.$confirm(text, "提示", {
                    type: "warning",
                    showCancelButton: flag,
                    center: true,
                  }).then(() => {
                    if (text == "该渠道存在分账的商户，请先关闭渠道分账配置")
                      return;
                    params.mchStatus = val;
                    if (val) {
                      mchStatusDisableUpdate(params.id).then((res) => {
                        if (res.code == "SUCCESS") {
                          this.getTableData1();
                        }
                      });
                    } else {
                      mchStatusEnableUpdateAPI({
                        id: params.id,
                        channelNo: params.channelNo,
                      }).then((res) => {
                        if (res.code == "SUCCESS") {
                          this.getTableData1();
                        }
                      });
                    }
                  });
                },
              },
            });
          },
        },
      ],
      tableData2: [],
      titleName2: [
        {
          title: "渠道",
          props: "channelName",
        },
        {
          title: "所属公司",
          props: "channelCompanyName",
        },
        {
          title: "独立收款公司",
          props: "companyName",
        },
        {
          title: "商户名称",
          props: "mchName",
        },
        {
          title: "商户简称",
          props: "mchSimpleName",
        },
        {
          title: "商户类型",
          props: "merchantType",
          SpecialJudgment: (res) => {
            return res === 1 ? "特约商户" : res === 0 ? "普通商户" : "";
          },
        },
        {
          title: "商户号",
          props: "mchId",
        },
        {
          title: "商户KEY",
          props: "key",
        },
        {
          title: "商户证书(P12)",
          props: "certP12Path",
          SpecialJudgment: (res) => {
            return res ? res.slice(res.indexOf(".") + 1) : "";
          },
        },
        {
          title: "商户私钥(PEM)",
          props: "keyPemPath",
          SpecialJudgment: (res) => {
            return res ? res.slice(res.indexOf(".") + 1) : "";
          },
        },
        {
          title: "商户证书(PEM)",
          props: "certPemPath",
          SpecialJudgment: (res) => {
            return res ? res.slice(res.indexOf(".") + 1) : "";
          },
        },
        {
          title: "状态",
          props: "mchStatus",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.mchStatus,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
                activeValue: 0,
                inactiveValue: 1,
              },
              on: {
                change: (val) => {
                  let text = "";
                  let flag = true;
                  if (val) {
                    let obj = this.platFormList.find(
                      (i) =>
                        i.mchId == params.mchId &&
                        i.merchantType &&
                        i.channelNo == params.channelNo
                    );
                    if (obj && obj.ledgerRatioReceiveCount > 0) {
                      text = "该商户已分账，请先关闭分账配置";
                      flag = false;
                    } else {
                      text = "禁用后，当前公司的线上交易使用渠道的默认配置商户";
                    }
                  } else {
                    if (
                      this.tableData.find(
                        (i) => i.channelNo == params.channelNo && !i.mchStatus
                      )
                    )
                      text = "启用后，该公司使用当前商户进行线上交易";
                    else text = "请先启用当前渠道的默认配置商户";
                  }
                  this.$confirm(text, "提示", {
                    type: "warning",
                    showCancelButton: flag,
                    center: true,
                  }).then(() => {
                    if (text == "请先启用当前渠道的默认配置商户") return;
                    if (text == "该商户已分账，请先关闭分账配置") return;
                    params.mchStatus = val;
                    if (val) {
                      mchStatusDisableUpdate(params.id).then((res) => {
                        if (res.code == "SUCCESS") {
                          this.getTableData2();
                        }
                      });
                    } else {
                      mchStatusEnableUpdateAPI({
                        id: params.id,
                        channelNo: params.channelNo,
                      }).then((res) => {
                        if (res.code == "SUCCESS") {
                          this.getTableData2();
                        }
                      });
                    }
                  });
                },
              },
            });
          },
        },
      ],
      active: "1",
      value: null,
      valueName: null,
      companyList: [],
      loading: false, //添加确认按钮防抖定时器
      platFormList: [],
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.dialogTitle = "";
        this.ruleForm = this.$options.data().ruleForm;
        // this.list = [];
        this.certP12PathList = [];
        this.keyPemPathList = [];
        this.certPemPathList = [];
      }
    },
    "ruleForm.merchantType"(val) {
      if (val === 0) {
        this.rules.certP12Path[0].required = true;
        this.rules.keyPemPath[0].required = true;
        this.rules.certPemPath[0].required = true;
      } else {
        this.rules.certP12Path[0].required = false;
        this.rules.keyPemPath[0].required = false;
        this.rules.certPemPath[0].required = false;
      }
    },
  },
  mounted() {
    this.getTableData1();
    this.getQueryWechatMchLedgerPage();
    this.getQueryChannelList();
  },
  methods: {
    // 获取平台分账列表
    getQueryWechatMchLedgerPage() {
      queryWechatMchLedgerPageAPI({ pageSize: 100, currentPage: 1 }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.platFormList = res.data.list;
          }
        }
      );
    },
    getTableData1() {
      queryWechatCertificatePageAPI({ currentPage: 1, pageSize: 10000 }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.tableData = res.data?.list || [];
          }
        }
      );
    },
    getTableData2() {
      independentWechatPage({ currentPage: 1, pageSize: 10000 }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData2 = res.data?.list || [];
        }
      });
    },
    /**
     * @description 打开弹窗
     */
    openDialogFun(title, row) {
      this.dialogTitle = title;
      if (title === "查看") {
        this.ruleForm = JSON.parse(JSON.stringify(row));
        if (this.active == "2") {
          getCompanyTree({ parentId: row.channelCompanyId }).then((res) => {
            if (res.code === "SUCCESS") {
              this.companyList = res.data[0].children
                ? res.data[0].children
                : [];
              this.value = row.companyId;
              this.valueName = row.companyName;
            }
          });
        }
        if (row.certP12Path) {
          this.certP12PathList.push({ url: host.imgURL + row.certP12Path });
          this.ruleForm.certP12Path = row.certP12Path.slice(
            row.certP12Path.indexOf(".") + 1
          );
        }
        if (row.keyPemPath) {
          this.keyPemPathList.push({ url: host.imgURL + row.keyPemPath });
          this.ruleForm.keyPemPath = row.keyPemPath.slice(
            row.keyPemPath.indexOf(".") + 1
          );
        }
        if (row.certPemPath) {
          this.certPemPathList.push({ url: host.imgURL + row.certPemPath });
          this.ruleForm.certPemPath = row.certPemPath.slice(
            row.certPemPath.indexOf(".") + 1
          );
        }
      }
      this.dialogVisible = true;
    },
    confirmFun() {
      if (this.dialogTitle === "查看") {
        this.dialogVisible = false;
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.active == "1") this.ruleForm.wechatType = 0;
          else this.ruleForm.wechatType = 1;
          saveWechatCertificateAPI(this.ruleForm)
            .then((res) => {
              if (res.code == "SUCCESS") {
                this.dialogVisible = false;
                this.loading = false;
                if (this.active == "1") this.getTableData1();
                else this.getTableData2();
              } else this.loading = false;
            })
            .catch(() => (this.loading = false));
        }
      });
    },
    /**
     * @description 上传图片
     */
    uploadSuccess(data, type) {
      this.ruleForm[type] = data;
      this.$refs.ruleForm.clearValidate(type);
    },
    /**
     * @description 获取渠道数据
     */
    getQueryChannelList() {
      queryChannelListAPI({ status: 0 }).then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
          this.list = res.data;
        }
      });
    },
    //选择渠道
    changeSelect(val) {
      this.ruleForm.companyId = null;
      this.ruleForm.companyName = null;
      this.value = null;
      this.valueName = null;
      const obj = this.channelList.find((i) => i.channelNo == val);
      this.ruleForm.channelName = obj.channelName;
      this.ruleForm.channelCompanyId = null;
      this.ruleForm.channelCompanyName = null;
      if (this.active == "2") {
        this.$refs.companySelect.clearHandle();
        this.ruleForm.channelCompanyId = obj.companyId;
        this.ruleForm.channelCompanyName = obj.companyName;
        getCompanyTree({ parentId: obj.companyId }).then((res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data[0] ? res.data[0].children : [];
          }
        });
      }
    },
    getAddGroupId(v) {
      this.ruleForm.companyId = v;
    },
    getName(v) {
      this.ruleForm.companyName = v;
    },
    handleClick() {
      if (this.active == "2") {
        this.getTableData2();
        let arr = this.tableData.filter((i) => !i.mchStatus);
        this.list = this.channelList.filter((i) =>
          arr.some((e) => e.channelNo == i.channelNo)
        );
      } else {
        this.getTableData1();
        this.list = this.channelList;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.merchantConfiguration-page {
  background-color: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  padding: 16px 20px;
  border-radius: 4px;
  box-sizing: border-box;
  .table-box {
    margin-top: 20px;
  }
  .dialog-box {
    .demo-ruleForm {
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
