<template>
  <div class="upload-component">
    <el-upload
      ref="elUpload"
      action
      :list-type="listType"
      :disabled="disabled"
      :accept="acceptType"
      :auto-upload="false"
      :multiple="false"
      :on-change="imageSuccessFun"
      :on-remove="imageListRemove"
      :file-list="fileList"
      :limit="2"
      :class="{ hide: disabled }"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <!-- <i class="el-icon-plus"></i> -->
    </el-upload>
  </div>
</template>

<script>
import { secretUploadImageAPI } from "@/api/lib/api.js";
export default {
  props: {
    fileArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    acceptType: {
      type: String,
      default: "",
    },
    listType: {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  mounted() {
    this.fileList = this.fileArr;
  },
  methods: {
    // 文件状态改变时的钩子
    imageSuccessFun(file, fileList) {
      let acceptArr = this.acceptType.split(",");
      if (!acceptArr.includes(file.name.slice(file.name.indexOf(".")))) {
        this.$message.error("请上传正确格式文件！");
        if (this.fileList.length <= 0) {
          this.fileList = [];
        } else {
          this.fileList = fileList.slice(0, 1);
        }
        return;
      }
      this.fileList = [file];
      if (file.uid) {
        // FormData 对象
        let form = new FormData();
        // 文件对象                       自定义上传名称
        form.append("file", file.raw, `.${file.name}`);
        secretUploadImageAPI(form).then((res) => {
          if (res.code == "SUCCESS") {
            this.$emit("uploadSuccess", res.data);
          }
        });
      }
    },
    // 文件列表移除文件时的钩子
    imageListRemove(file, fileList) {
      this.fileList = [];
    },
  },
};
</script>

<style lang="scss">
.upload-component {
  .hide {
    .el-upload {
      display: none !important;
    }
  }
  .el-upload,
  .el-upload-list__item,
  .el-upload-list__item-actions {
    // width: 95px;
    // height: 95px;
    // line-height: 95px;
  }
}
</style>
