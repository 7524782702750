var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-component" },
    [
      _c(
        "el-upload",
        {
          ref: "elUpload",
          class: { hide: _vm.disabled },
          attrs: {
            action: "",
            "list-type": _vm.listType,
            disabled: _vm.disabled,
            accept: _vm.acceptType,
            "auto-upload": false,
            multiple: false,
            "on-change": _vm.imageSuccessFun,
            "on-remove": _vm.imageListRemove,
            "file-list": _vm.fileList,
            limit: 2,
          },
        },
        [
          _c("el-button", { attrs: { size: "small", type: "primary" } }, [
            _vm._v("点击上传"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }